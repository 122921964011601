@import url('https://fonts.googleapis.com/css2?family=Headland+One&display=swap');

.home-background {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    font-family: "Headland One", serif;
    font-weight: 400;
    font-style: normal;
    color: white
}

.home-left {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 30vw;
}

.home-title {
    color: white;
    font-size: 6rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
}


.home-text {
    color: white;
    margin-top: 5vh;
    font-size: 1.3rem; 
}


.home-button {
    background-color: #A3816C;
    border: none;
    cursor: pointer;
    width: 60%;
    height: 5vh;
    border-radius: 100px;
    color: white;
    margin-top: 5vh;
    font-size: 1.3rem; 
}

.home-contact-form {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-contact-form-window {
    width: 20vw;
    height: 55vh;
    background-color: #25211E;
    border-radius: 20px;    
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.home-textfield {
    border: 1px solid #A3816C;
    background: none; 
    box-shadow: none; 
    outline: none;
    color: white;
    margin-top: 2vh;
    width: 80%;
    height: 4vh;
    border-radius: 10px;
    padding-left: 10px;
    font-family: "Headland One", serif;
    font-weight: 400;
    font-style: normal;
}

.home-textfield-description {
    border: 1px solid #A3816C;
    background: none; 
    box-shadow: none; 
    outline: none;
    color: white;
    margin-top: 2vh;
    width: 80%;
    height: 6vh;
    border-radius: 10px;
    padding-left: 10px;
    text-align: start;
    font-family: "Headland One", serif;
    font-weight: 400;
    font-style: normal;
    padding-top: 10px; /* Ajuster le padding en fonction de vos besoins */

}

.home-form-title {
    font-size: 1.8rem; 
    margin-top: 0px;
}

.home-form-text {
    font-size: 0.9rem; 
}
.home-form-button {
    background-color: #A3816C;
    border: none;
    cursor: pointer;
    width: 80%;
    height: 3vh;
    border-radius: 100px;
    color: white;
    margin-top: 3vh;
    font-size: 1rem; 
}

@media (max-width: 2100px) {
    .home-title {
        font-size: 4rem; 
    }
    .home-text {
        font-size: 1rem; 
    }
    .home-button {
        font-size: 1rem; 
    }
    .home-form-title {
        font-size: 1.8rem;
        margin-bottom: 0.3px;; 
    }
    .home-form-text {
        font-size: 0.9rem; 
    }
    .home-form-button {
        margin-top: 2vh;
    }
}
@media (max-width: 1670px) {
    .home-title {
        font-size: 3rem; 
    }
    .home-text {
        font-size: 0.7rem; 
    }
    .home-button {
        font-size: 0.7rem; 
    }
    .home-form-title {
        font-size: 1.4rem; 
    }
    .home-form-text {
        font-size: 0.6rem; 
    }
    .home-form-button {
        font-size: 0.6rem; 
    }
}
@media (max-width: 1250px) {
    .home-title {
        font-size: 2rem; 
    }
    .home-text {
        font-size: 0.5rem; 
    }
    .home-button {
        font-size: 0.5rem; 
    }
    .home-form-title {
        font-size: 1rem; 
    }
    .home-form-text {
        font-size: 0.4rem; 
    }
    .home-form-button {
        font-size: 0.5rem; 
    }
    .home-textfield {
        font-size: 0.5rem; 
        height: 7%;
    }
    .home-textfield-description {
        font-size: 0.5rem; 
        height: 10%;
    }
}
@media (max-width: 900px) {
    .home-form-title {
        font-size: 1rem; 
    }
    .home-form-text {
        font-size: 0.4rem; 
    }
    .home-form-button {
        font-size: 0.4rem; 
    }
}

